import React, {useEffect, useState} from "react";
import DataService from "../../DataService";
import {
    Card,
    CardContent,
    CardActions,
    Button,
    Skeleton,
    Pagination,
} from '@mui/material'
import {Toolbar} from "visuaui";
import DownloadIcon from "@mui/icons-material/Download";
import SearchVisuaImageList from "../../components/SearchVisuaImageList";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import Toast from '../../components/Toast'
import VisuaSearchTagModal from "../../components/VisuaSearchTagModal";
import moment from "moment";
import AddImageSearchModal from "../../components/AddImageSearchModal";
import VisuaSearchInput from "../../components/VisuaSearchInput";

export default function VisuaSearch(
    {}
) {
    const [items, setItems] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [tempTxt, setTempText] = useState('');
    const [selectSearchField, setSelectSearchField] = useState('ids');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [toastData, setToastData] = useState({
        open: false,
        severity: 'info',
        message: '',
        duration: 0,
    })
    const [openTagModal, setOpenTagModal] = useState(false);
    const [openAddImage, setOpenAddImages] = useState(false);
    const PAGE_SIZE = 50;

    useEffect(() => {
        setLoading(true);
        const params = {
            page: currentPage,
            pageSize: PAGE_SIZE,
        }
        if (selectSearchField === 'ids') {
            params.ids = searchText?.split(',') ?? [];
        } else {
            params.meta = searchText ?? '';
        }
        DataService.getItems(params)
            .then(response => {
                if (response.status === 200 && response.data) {
                    setTotalCount(response.headers["x-total-count"]);
                    setPageCount(Math.ceil(response.headers["x-total-count"] / PAGE_SIZE))
                    setItems(response.data.items);
                } else {
                    setTotalCount(0);
                    setPageCount(0)
                    setItems([]);
                }
            })
            .then(_ => {
                setLoading(false);
            })
    }, [currentPage, searchText, selectSearchField]);

    const clickOnDeleteImg = (item) => {
        setSelectedItem(item);
        setOpenDeleteModal(true);
    }

    const deleteItem = () => {
        setOpenDeleteModal(false);
        setLoading(true);
        const {id} = selectedItem;
        DataService.disableItem(id)
            .then(response => {
                let data = response.data || {};
                let status = data.status || 0;
                if (status === 200) {
                    setTotalCount(
                        totalCount - 1
                    );
                    setToastData({
                        duration: 5000,
                        severity: 'success',
                        message: 'Operation carried out successfully',
                        open: true,
                    })

                    const newItems = items.filter(
                        item => item.id !== id
                    )
                    setItems(newItems);
                } else {
                    setToastData({
                        duration: 5000,
                        severity: 'error',
                        message: 'XHR Failed for disableItem',
                        open: true,
                    })
                }
            })
            .then(_ => {
                setLoading(false);
            })
            .catch(_ => {
                setToastData({
                    duration: 5000,
                    severity: 'error',
                    message: 'XHR Failed for disableItem',
                    open: true,
                })
            })
    }

    const clickTagModal = (item) => {
        setSelectedItem(item);
        setOpenTagModal(true);
    }

    const saveNewMeta = (meta) => {
        setOpenTagModal(false);
        DataService.putItemMeta(selectedItem.id, meta).then(response => {
            const data = response.data || {};
            const status = response.status || 0;

            console.log({response, status});

            if (status === 200) {
                setToastData({
                    duration: 5000,
                    severity: 'success',
                    message: "Thank you. The new tag has been applied.",
                    open: true,
                })
                const copiedItems = [...items];
                const indexItemToUpdate = copiedItems.findIndex(el => el.id === selectedItem.id);
                selectedItem.meta = meta;
                copiedItems[indexItemToUpdate] = selectedItem;
                setItems(copiedItems);
            } else {
                setToastData({
                    duration: 5000,
                    severity: 'error',
                    message: "Something went wrong. No tag applied",
                    open: true,
                })
            }
        }).catch(_ => {
            setToastData({
                duration: 5000,
                severity: 'error',
                message: 'XHR Failed for putItemMeta',
                open: true,
            })
        });

    }

    const closeDeleteDialog = () => {
        setSelectedItem({});
        setOpenDeleteModal(false);
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const onCSVExport = async () => {
        setToastData({
            open: true,
            severity: 'success',
            duration: 0,
            message: `Generated report for 0/${totalCount} listings (0%)`,
        })
        const chunkSize = 75;
        let results = [];
        for (let index = 1; index <= Math.ceil(totalCount / chunkSize); index++) {
            const params = {
                page: index,
                pageSize: chunkSize,
            }
            if (selectSearchField === 'ids') {
                params.ids = searchText?.split(',') ?? [];
            } else {
                params.meta = searchText ?? '';
            }
            const chunkedResults = await DataService.getItems(params)
                .then(response => {
                    if (response.status === 200 && response.data) {
                        return response.data.items;
                    }
                    return [];
            }).catch(error => {
                console.error(error);
                setToastData({
                    open: true,
                    severity: 'error',
                    duration: 5000,
                    message: `An error occurred while generating the CSV report`,
                })
            });
            const percentage = ((index / Math.ceil(totalCount / chunkSize)) * 100).toFixed(0);
            setToastData({
                open: true,
                severity: 'success',
                message: `Generated report for ${Math.min(index * chunkSize, totalCount)}/${totalCount} listings (${percentage}%)`,
            })
            results = results.concat(chunkedResults);
            await sleep(500);
        }

        setToastData({
            open: true,
            duration: 5000,
            severity: 'success',
            message: `Downloading CSV report....`,
        })

        let csvContent = "";
        const header = ["ID", "Name", "Media URL"];
        const csvData = results.map(item => {
            return [
                item.id,
                item.name,
                item.mediaUrl
            ].join(",");
        });

        csvContent += `${header.join(",")}\n`;
        csvContent += csvData.join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv"
        });
        const objectUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.setAttribute("href", objectUrl);

        const fileName = `export-${moment().format("YYYY-MM-DD")}`;
        link.setAttribute("download", `${fileName}.csv`);
        link.click();
    }




    return (
        <>
            <Card
                raised={true}
                sx={{
                    p: 1,
                    mb: 5,
                }}
            >
                <Toolbar removeBackground={true}>
                    <VisuaSearchInput
                        isLoading={loading}
                        selectSearchField={selectSearchField}
                        setSelectSearchField={setSelectSearchField}
                        search={(txt) => {
                            setCurrentPage(1)
                            setSearchText(txt)
                        }}
                    />

                    <Button
                        color={'success'}
                        variant={'contained'}
                        sx={{
                            ml: 'auto',
                            mr: 1
                        }}
                        onClick={_ => {
                            setOpenAddImages(true);
                        }}
                        disabled={isLoadingImg}
                    >
                        Add Images
                    </Button>
                    <Button
                        color={'info'}
                        variant={'contained'}
                        startIcon={<DownloadIcon/>}
                        onClick={() => {
                            onCSVExport()
                        }}

                    >
                        CSV
                    </Button>
                </Toolbar>
                <CardContent>
                    <p
                        style={{
                            backgroundColor: "#BCC5CF",
                            padding: "5px",
                            fontSize: '14px',
                        }}
                    >
                        {
                            loading
                                ? <Skeleton variant={'text'}/>
                                : (totalCount === 1)
                                    ? <span>{Number(totalCount).toLocaleString()} <b>Item</b></span>
                                    : <span>{Number(totalCount).toLocaleString()} <b>Items</b></span>
                        }
                    </p>
                    <SearchVisuaImageList
                        loading={loading}
                        items={items}
                        onDeleteClick={clickOnDeleteImg}
                        onTagClick={clickTagModal}
                    />
                </CardContent>
                {
                    pageCount > 1 &&
                    <CardActions sx={{
                        justifyContent: 'center',
                    }}>
                        <Pagination
                            count={pageCount}
                            variant="outlined"
                            page={currentPage}
                            color="primary"
                            onChange={(event, value) => {
                                setCurrentPage(value);
                            }}
                        />
                    </CardActions>
                }
            </Card>
            <ConfirmDeleteModal
                onConfirm={deleteItem}
                open={openDeleteModal}
                handleClose={closeDeleteDialog}
            />
            <Toast
                open={toastData.open}
                message={toastData.message}
                severity={toastData.severity}
                autoHideDuration={toastData.duration}
                handleClose={_ => setToastData(
                    prevState => {
                        return {
                            ...prevState,
                            open: false,
                        }
                    }
                )}
            >

            </Toast>
            <VisuaSearchTagModal
                open={openTagModal}
                handleClose={_ => setOpenTagModal(false)}
                handleSave={saveNewMeta}
                item={selectedItem}
            />
            <AddImageSearchModal
                open={openAddImage}
                handleClose={_ => setOpenAddImages(false)}
                updateTotal={(total) => {
                    setTotalCount((prev) => parseInt(prev, 10) + parseInt(total, 10));
                }}
            />
        </>
    )
}
