import * as React from 'react';
import PropTypes from 'prop-types';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Skeleton from "@mui/material/Skeleton";
import Image from 'material-ui-image';
import {Button, Grid, IconButton, ImageListItemBar, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {useState} from "react";
import CloseIcon from "@mui/icons-material/Close";


function VisualSearchDetail({item, handleClose}){

    const details = [
        { label: "Id", value: item?.id },
        { label: "Name", value: item?.name },
        { label: "Time Created", value: item?.timeCreated },
        { label: "Visual Class Id", value: item?.visualClassId },
    ];

    return (
        <Dialog
            open={!!item}
            onClose={() => handleClose()}
            maxWidth={'lg'}
            fullWidth={true}
        >
            {
                !!item && (
                   <>
                       <DialogTitle style={{
                           display: 'flex',
                           alignItems: 'center',
                           borderBottom: '1px solid #ccc',
                           mb: 2,
                       }}>
                           <span>
                               {item.name ?? 'Unnamed Visual Search Item'}
                           </span>
                           <IconButton
                            sx={{ml: 'auto'}}
                            onClick={() => handleClose()}
                           >
                               <CloseIcon />
                           </IconButton>
                       </DialogTitle>
                       <DialogContent>
                           <div
                               style={{
                                   display: 'flex',
                                   marginTop: '5px'
                               }}
                           >
                               <div
                                style={{
                                    width: '66.6%'
                                }}
                               >
                                   <img
                                       style={{
                                           width: '100%',
                                       }}
                                       src={item.mediaUrl}
                                       alt={item.name ?? 'unnamed Item'}
                                   />
                               </div>
                               <div
                                   style={{
                                       width: '33.3%'
                                   }}
                               >
                                   <Typography variant="h4" fontWeight="bold">
                                       Item Info
                                   </Typography>
                                   <hr/>
                                   <Grid container>
                                       {details.map((detail, index) => (
                                           <Grid container item key={index} spacing={1}>
                                               <Grid item>
                                                   <Typography variant="body1" fontWeight="bold">
                                                       {detail.label}:
                                                   </Typography>
                                               </Grid>
                                               <Grid item>
                                                   <Typography variant="body1">{detail.value}</Typography>
                                               </Grid>
                                           </Grid>
                                       ))}
                                   </Grid>
                               </div>
                           </div>
                       </DialogContent>

                   </>
                )
            }

        </Dialog>
    )
}

function LoadedState({items, onDeleteClick, onTagClick}) {
    console.log({items})
    const [details, setDetails] = useState(null);
    return (
        <>
            <VisualSearchDetail
                item={details}
                handleClose={() => {
                    setDetails(null)
                }}
            />
            <ImageList cols={5} gap={10}>

                {
                    items.map((item) => (
                            <ImageListItem
                                key={item.id}
                                variant="masonry"
                            >
                                <Image
                                    src={item.mediaUrl}
                                    alt={item.name}
                                    onClick={() => setDetails(item)}
                                    style={{
                                        cursor: 'pointer',
                                        "&:hover": {
                                            border: "1px solid #1DB8E0"
                                        },
                                    }}
                                />
                                <ImageListItemBar
                                    title={item.name}
                                    subtitle={item.timeCreated}
                                    actionIcon={
                                        <>
                                            <IconButton
                                                sx={{ color: 'error.main' }}
                                                onClick={_ => onDeleteClick(item)}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                            <IconButton
                                                sx={{ color: 'primary.main' }}
                                                onClick={_ => onTagClick(item)}
                                            >
                                                <LocalOfferIcon/>
                                            </IconButton>
                                        </>
                                    }

                                />
                            </ImageListItem>
                        )
                    )
                }

            </ImageList>
        </>


    )
}

function LoadingState() {
    return (
        <ImageList cols={5} gap={10}>
            {[...Array(50).keys()].map((item) => (
                <ImageListItem
                    key={item}
                    style={{
                        backgroundColor: '#f2f2f2',
                        padding: '3px'
                    }}
                    sx={{
                        cursor: 'pointer'
                    }}
                >
                    <Skeleton height={200} variant={'rectangular'}/>
                </ImageListItem>
            ))}
        </ImageList>
    )
}




function SearchVisuaImageList(
    {
        loading,
        items,
        onDeleteClick,
        onTagClick,
    }
) {
    return (
        <>
            {
                loading
                    ? <LoadingState/>
                    : (
                        <LoadedState
                            items={items}
                            onDeleteClick={onDeleteClick}
                            onTagClick={onTagClick}
                        />
                    )
            }
        </>
    )
}

SearchVisuaImageList.propTypes = {
    loading: PropTypes.bool,
    items: PropTypes.array,
}

SearchVisuaImageList.defaultProps = {
    loading: false,
}

export default SearchVisuaImageList;
