import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from "@mui/material/TextField";
import {Button, IconButton, MenuItem, Select, Tooltip} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {useState} from "react";
import Label from "./Label";
import InfoIcon from "@mui/icons-material/Info";

function VisuaSearchInput(
    {search, isLoading, selectSearchField, setSelectSearchField}
) {
    const [tempTxt, setTempText] = useState('');

    const isError = selectSearchField === 'ids'
        && tempTxt.length > 0
        && tempTxt.split(',').filter((el) => isNaN(parseInt(el,10))).length > 0

    return (
        <div style={{
            display: 'flex',
        }}>
            <Tooltip
                open={isError}
                title={'When you search by Ids, you can only instert numbers and comas'}
            >
            <TextField
                sx={{mr: 1,}}
                label="Search..."
                variant="standard"
                onChange={(e) => {
                    console.log(e.target)
                    setTempText(e.target.value)
                }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        search(tempTxt);
                    }
                }}
                error={isError}
            />
            </Tooltip>
            <Select
                variant={'standard'}
                sx={{mx: 1}}
                value={selectSearchField}
                renderValue={(txt) => <span><Label>in</Label> {txt}</span>}
                onChange={
                    (event) => {
                        console.log(event)
                        setTempText('')
                        const value = event.target.value;
                        setSelectSearchField(value)
                    }
                }
            >
                {['ids', 'name'].map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
            </Select>

                <Button
                    sx={{mx: 1, minWidth: 50, height: '100%', mt: 'auto'}}
                    color={isError? 'error': 'primary'}
                    variant="contained"
                    disabled={isLoading || isError}
                    onClick={() => {
                        search(tempTxt)
                    }}
                >
                    <SearchIcon />
                </Button>

            {
                selectSearchField === 'ids' && (
                    <Tooltip title={"You can search for multiple ids, separating the numeric values with commas ','"}>
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                )
            }
        </div>
    )
}

VisuaSearchInput.propTypes = {}

VisuaSearchInput.defaultProps = {}

export default VisuaSearchInput;
